// fonts
$primaryBlack: 'Cinzel', serif;
$primaryTitle: 'Cormorant Garamond', serif;
$primaryGreen: 'Great Vibes', cursive;
$secondary: 'Nunito', sans-serif;

// colors
$white: #ffffff;

$gray100: #f2f2f2;
$gray300: #c4c4c4;
$gray400: #9a9a9a;
$gray500: #818181;
$gray600: #5e5e5e;
$gray800: #323232;
$gray900: #242424;

$black25: rgba(0, 0, 0, 0.25);
$black35: rgba(0, 0, 0, 0.35);
$black45: rgba(0, 0, 0, 0.45);
$black75: rgba(0, 0, 0, 0.75);
$black: #000000;

$primary: #5f8449;
$blue: #3061bc;
$primary95: #5f8449f5;
$primaryLight: #5f84492f;
$secondaryLight: #fefae0;
$yellow: rgba(210, 160, 21, 0.6);
$yellowLight: rgb(255, 226, 147);
$red: #8c0000;
