@import 'submitting.scss';
@import 'bp10.scss';
@import 'nav-list.scss';

@media only screen and (min-width: 530px) {
  @import 'bp20.scss';
}

@media only screen and (min-width: 800px) and (min-height: 540px) {
  @import 'bp30.scss';
}

@media only screen and (min-width: 1080px) and (min-height: 540px) {
  @import 'bp35.scss';
}

@media only screen and (min-width: 1315px) and (min-height: 540px) {
  @import 'bp40.scss';
}

@media only screen and (min-width: 1500px) and (min-height: 540px) {
  @import 'bp50.scss';
}

@media only screen and (min-height: 720px) {
  @import 'bph30.scss';
}
