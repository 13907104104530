@import 'vars.scss';

.dropdown {
  list-style: none;
  margin: 0;
  padding-left: 0;
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 10px;
  left: 0;
  display: none;
  border-radius: 10px;
  box-shadow: 0px 4px 8px $black25;
  background-color: white;
}

.dropdown-item {
  display: block;
  float: left;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;

  &:hover {
    cursor: pointer;
  }
}

.dropdown-item__text {
  color: $gray900;
}

.nav-underline:hover > .dropdown,
.dropdown:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

.dropdown-item {
  clear: both;
  width: 100%;
}
