.slides {
  height: calc(95% - 139px);
  top: 139px;
  left: 17.5%;
  margin: unset;

  &--tall {
    height: calc(100% - 139px);
    top: 139px;
  }
}

.slide__contact-form {
  width: 350px;
}

.contact-form__input--text-area {
  height: 170px;
}

.nav {
  justify-content: space-around;
}
