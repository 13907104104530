@import 'vars.scss';

// body
body {
  margin: 0;
  background-color: $gray100;
  font-family: $secondary;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.recital {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.flyer {
  &__image {
    max-height: calc(100% - 150px);
    max-width: calc(100% - 50px);
    margin: 25px;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.25));
  }

  &__button {
    opacity: 0.9;
    border: none;
    color: #323232;
    background: white;
    z-index: 10;
    position: fixed;
    bottom: 18px;
    right: 18px;
    font-family: 'Cinzel', serif;
    padding: 3px;
    font-style: italic;
    font-size: 14px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 100px;
  }

  &__button-inner {
    border: 1px solid #5b6689;
    border-radius: 100px;
    padding: 10px 15px;
  }

  &__header {
    font-weight: 500;
    color: $gray800;
    font-family: 'Cinzel', serif;
    padding: 20px 20px 15px 20px;
    text-align: center;
    z-index: 3;
    background-color: $gray100;
    border-bottom: 1px solid gray;
    position: sticky;
    top: 0;
    width: calc(100% - 90px);
  }

  &__header-title {
    font-size: 1.75rem;
  }

  &__header-link {
    color: $blue;
    font-size: 0.75rem;
    cursor: pointer;
    text-decoration: none;
    line-height: 0.25rem;
  }
}

.esa-blue {
  color: $blue;
  overflow-wrap: break-word;
}

#esa-notice {
  font-family: 'Nunito';
  font-weight: 300;
  color: white;
  display: flex;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  background: $primary95;
  // background: $blue;
  text-align: left;
  line-height: 2.5;
  overflow: hidden;
  transform: translate(0%, -100%);
  transition: transform 350ms;
  padding: 20px 15px 0;
  justify-content: center;
  font-size: 1rem !important;
}

.esa-margin-top {
  margin-top: 53px;
}

.esa-learn-more {
  color: white;
  font-weight: 400;
}

#close {
  height: 100%;
}

.esa-close {
  height: 18px;
  padding: 10px 10px 0;
  top: 1px;
  position: relative;
  cursor: pointer;
  filter: invert(100%) sepia(0%) saturate(9%) hue-rotate(149deg)
    brightness(105%) contrast(100%);
}

.availability-link {
  color: $gray800;
}

// .wave-home {
//   position: sticky;
//   top: 0;
//   filter: drop-shadow(0 3px 3px $black25);
//   margin-bottom: -85px;
//   max-height: 266px;
//   width: 8034px;

//   &__text {
//     position: fixed;
//     font-family: $secondary;
//     // color: $gray800;
//     color: $gray100;

//     &--small {
//       font-size: smaller;
//       line-height: 1.5rem;
//     }

//     &--large {
//       font-size: x-large;
//       line-height: 2.1rem;
//     }
//   }
// }

.data-loading {
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: x-large;
  font-style: italic;
  color: $gray600;
  transform: scale(0.8);
}

.desktop-calendar {
  display: none !important;
}

.calendar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &__days {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__nav {
    display: none;
    align-items: center;
    height: 75%;
    transition: transform 400ms;

    &:hover {
      transform: scale(1.1);
      transition: transform 400ms;
    }
  }

  &__nav-button {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    background-color: $primaryLight;
    border: 2px solid $primary;
    color: $primary;
    cursor: pointer;

    &:disabled {
      background-color: $gray100;
      border-color: $gray300;
      color: $gray300;
    }
  }
}

.header {
  &__day {
    font-size: x-small;
  }

  &__dayNum {
    font-size: small;
    font-weight: bold;
  }

  &__text-wrapper {
    width: 50px;
    height: 50px;
    padding: 7px;
    border-radius: 200px;
    background-color: $primaryLight;
    box-shadow: 0 1.5px 2px $black25;
    transform: scale(0.9);
  }
}

.day {
  width: 100%;
  margin: 5px;
  margin-bottom: 0px;
  padding-bottom: 5px;
  border-bottom: 1.5px solid $gray100;
  height: 100%;
  display: flex;

  &__last {
    border-right: unset;
  }

  &__header {
    padding-right: 8px;
    border-right: 1.5px solid $gray100;
    margin-right: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__event {
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 5px;
    text-align: center;
    background-color: $primaryLight;
    box-shadow: 0 1.5px 2px $black25;

    &--unavailable {
      border: unset;
      background-color: unset;
      box-shadow: unset;
    }
  }

  &__text {
    font-size: small;
    &--small {
      font-size: smaller;
      line-height: 12px;
    }

    &--unavailable {
      font-weight: bold;
      color: $gray600;
      text-align: center;
    }
  }
}

.schedule-button {
  padding: 0.625rem;
  margin-bottom: 1.5rem;
  background-color: $yellow;
  box-shadow: 0 4px 10px $black25;
  outline: none;
  border-radius: 0.625rem;
  max-width: 325px;
  width: 100%;
  border: none;
  font-size: 1rem;
  color: $gray800;
  transition: transform 400ms;
  cursor: pointer;
  text-decoration: none;
  text-align: center;

  &:hover {
    transform: scale(1.05);
  }

  &--green {
    background-color: $primary;
    color: $white;
  }

  &--disabled {
    cursor: not-allowed;

    &:hover {
      transform: unset;
    }
  }

  &--full-width {
    max-width: unset;
    border-radius: 5px;
  }
}

.submit-show {
  display: inline-block;
}

.submit-hide {
  display: none;
}

// .landing-page-image
.landing-page-image {
  width: 40rem;
  max-width: 100%;
  left: -8rem;
  bottom: 0;
  position: absolute;
  z-index: 0;
}

.hero-image {
  width: 100%;
  max-width: 325px;
}

.headshot {
  width: 225px;
  height: 300px;
  object-fit: cover;
  box-shadow: -5px 5px 10px $black25;
  border-radius: 5px 5px 0 0;
  // background-color: #F4D03F;
  // background-image: linear-gradient(132deg, #F4D03F 0%, #148f77 100%);

  &--small {
    width: 175px;
    height: 260px;
    object-fit: cover;
    margin: 30px;
    box-shadow: -5px 5px 10px $black25;
    border-radius: 5px;
  }
}

.headshot-bio-link {
  width: 225px;
  font-size: 16px;
  background-color: $gray800;
  opacity: 90%;
  color: $white;
  padding: 5px 0;
  border-radius: 0 0 5px 5px;
}

.headshot-wrapper {
  display: flex;
  flex-direction: column;
}

.headshot-img-wrapper {
  text-align: center;
}

.headshot-bio-wrapper {
  transform: scale(1);
  transition: transform 300ms;
  cursor: pointer;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: unset;
  text-decoration: unset;

  &:hover {
    transform: scale(1.02);
  }
}

// .slides
.slides {
  height: calc(100% - 105px);
  width: 85vw;
  top: 105px;
  border-radius: 15px 0 0 0;
  box-shadow: -14px 14px 35px $black25;
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: #fffffff6;
  max-width: 80vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.6s;
}

.slides-inner {
  margin: 25px 35px;
  // 100% minus sum of inner elems margins top/bottom
  height: calc(100% - 50px);
}

.nav-wrapper {
  width: 100%;
  z-index: 1;
  background-color: #ffffffe0;
  position: absolute;
  left: 0;
}

// .nav
.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px;
}

// .nav-left
.nav-title {
  // font-size: 50px;
  font-size: 1.75rem;
  font-weight: 400;
  font-family: $primaryBlack;
  display: flex;
  text-align: center;

  &--color {
    color: $primary;
    font-weight: 600;
    font-family: $primaryGreen;
    font-size: 2.375rem;
  }

  &__nav {
    height: 1.1rem;
    width: 1.1rem;
    padding: 0.375rem 0.45rem;
    margin-right: 1rem;
    border: 0.1rem solid black;
    border-radius: 0.175rem;
    opacity: 50%;
  }
}

.nav-modal {
  display: none;
  left: -215px;
  width: 175px;
  position: absolute;
  z-index: 5;
  height: 100vh;
  background-color: $white;
  padding: 20px;

  &--transition {
    display: block;
    transition: left 500ms;
  }

  &--open {
    display: block;
    left: 0;
    transition: left 500ms;
  }

  &__close {
    margin-left: 93%;
    height: 1.2rem;
  }
}

.nav-modal-back {
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: absolute;
  z-index: 4;
  left: 0;

  &--transition {
    display: block;
    opacity: 0%;
    transition: opacity 500ms;
  }

  &--open {
    display: block;
    opacity: 60%;
    transition: opacity 500ms;
  }
}

// .nav-right
.nav-items {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

  &--modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.1rem;

    .nav-item {
      &::before {
        content: unset;
      }
    }

    .nav-underline {
      padding: 0 0 15px 15px;

      &::before {
        content: unset;
      }
    }
  }
}

// .nav-item
.nav-item {
  color: $gray900;
  font-weight: 500;

  &::before {
    content: '';
    border-radius: 4px;
    width: 8px;
    height: 8px;
    background-color: $gray600;
    position: absolute;
    transform: translate(-12px, 7px);
  }

  &--active {
    color: $primary;

    &::before {
      background-color: $primary;
    }
  }
}

// .nav-underline
.nav-underline {
  position: relative;
  display: block;
  padding: 10px 25px;
  text-decoration: none;
  color: unset;

  // Underline styles
  &::before {
    content: '';
    position: absolute;
    bottom: 5px;
    display: block;
    height: 3px;
    border-radius: 3px;
    width: calc(100% - 50px);
    background-color: $white;
    transition-timing-function: linear;
    transition-duration: 150ms;
    transition-property: background-color, transform;
    transform: translate(0) scaleX(0);
    transform-origin: center;
  }

  &:hover::before {
    transform: translate(-6px) scaleX(1.4);
    background-color: $gray300;
  }

  &:hover {
    cursor: pointer;
  }
}

.duration-wrapper {
  display: flex;
  align-content: center;
  margin: 1rem 0;
}

.duration-image {
  margin-right: 0.3125rem;
  height: 1.25rem;
}

.slide-wrapper {
  overflow-y: scroll;
}

// .slide
.slide {
  display: none;
  display: flex;
  flex-direction: column;
  opacity: 100%;
  transition: opacity 500ms;
  height: 100%;

  &--transition {
    display: flex;
    flex-direction: column;
    opacity: 0%;
    transition: opacity 150ms;
  }

  &--consult {
    height: unset;
  }

  &__sections {
    padding: 20px;
    margin-top: 55px;
    display: flex;
    flex-direction: column;

    &--no-header {
      margin-top: unset;
    }

    &--no-padding {
      padding: 0 20px;
    }

    &--no-padding-bottom {
      padding: 20px 20px 0px;
    }

    &--no-margin {
      margin-top: 0;
    }

    &--calendar {
      height: 100%;
    }

    &--center {
      justify-content: center !important;
    }

    &--text-center-full {
      text-align: center;
      margin: 20px;
      padding: 0;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-full-width {
      width: 100%;
    }

    &--reset {
      width: unset;
    }

    &--no-center {
      justify-content: unset;
    }
  }

  &__title {
    // font-size: 26px;
    font-size: 1.625rem;
    font-weight: 600;
    color: $gray900;
    margin-bottom: 15px;
  }

  &__sub-title {
    // font-size: 16px;
    font-size: 1rem;
    font-weight: 300px;
    color: $gray400;
    margin-bottom: 15px;
  }

  &__detail {
    font-size: 1rem;
    color: $gray600;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  &__texts {
    display: flex;
    flex-direction: column;

    flex-direction: row;
    align-items: center;
  }

  &__text {
    &--title-font {
      font-family: $primaryTitle;
    }

    &--availability {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $gray500;
      font-style: italic;
      font-weight: 300;
    }

    &--small {
      color: $gray500;
      font-size: 1rem;
    }

    &--italic {
      font-weight: 400;
      font-style: italic;
    }

    &--medium {
      // font-size: 30px;
      font-size: 1.5rem;
      font-weight: 500;
    }

    &--normal {
      font-size: 1.2rem;
      font-weight: 400;
    }

    &--medium-ish {
      font-size: 1.3rem;
      font-weight: 500;
      color: $gray900;
    }

    &--margin {
      margin-bottom: 0.5rem;
    }

    &--margin-all {
      margin-left: 1rem;
      margin-bottom: 0.5rem;
    }

    &--align {
      align-self: center;
    }

    &--italic-title {
      font-style: italic;
      font-size: 1.15rem;
    }

    &--available {
      font-style: italic;
      // font-size: 18px;
      font-size: 1rem;
    }

    &--unavailable {
      color: $gray400;
      font-style: italic;
      // font-size: 18px;
      font-size: 1rem;
    }

    &--bio {
      max-width: 600px;
    }
  }

  &__header {
    // font-size: 36px;
    font-size: 2.25rem;
    font-weight: 500;
    color: $gray800;
    display: flex;
    border-bottom: 2px solid $gray100;
    font-family: 'Cinzel', serif;
    padding: 20px 20px 0 20px;
    top: 0;
    position: absolute;
    background: white;
    z-index: 3;
    width: 100%;
  }

  &__center-wrapper {
    display: flex;
    justify-content: center;
  }
}

.icon-set {
  display: flex;
  margin-bottom: 20px;
}

.icon-wrapper {
  display: flex;
  margin-right: 30px;
  margin-bottom: 1rem;
}

.icon-small {
  margin-right: 10px;
  height: 2.1875rem;
  width: 2.1875rem;

  &:hover {
    cursor: pointer;
  }
}

.pricing-chart {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  max-width: 325px;

  &__price-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__header {
    border-radius: 10px 10px 0 0;
    margin-bottom: 5px;
    background-color: $primary;
    box-shadow: 0px 4px 8px $black25;
    padding: 10px;
    text-align: center;
    color: $white;
    font-weight: 500;
    font-size: 1.15rem;
    min-width: 250px;
  }

  &__section {
    padding: 15px 20px;
    background-color: $white;
    box-shadow: 0px 4px 8px $black25;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__lesson {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  &__lesson-title {
    margin-bottom: 10px;
  }

  &__break {
    width: 5px;
    height: 5px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 100px;
    justify-content: space-around;
  }

  &__title {
    color: $gray600;
    // font-size: 20px;
    font-size: 0.9rem;
    font-weight: 600;
  }

  &__price {
    color: $gray600;
    // font-size: 65px;
    font-size: 2rem;
    font-weight: 500;
  }

  &__line-right {
    height: 100%;
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid $gray100;
  }
}

.about-slide {
  width: 100%;

  &__header {
    width: 100%;
    border-bottom: 2px solid $primary;
    margin-bottom: 15px;

    &--no-line {
      border-bottom: none;
    }
  }
}

.about-wrapper {
  display: flex;
  flex-direction: column;
}

.availability-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;

  &--hide {
    display: none;
  }
}

.day-label {
  width: 75px;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 0.75rem;
  background-color: $primary;
  text-align: center;
  color: $white;
  flex-grow: 1;

  &--time {
    background-color: $white;
    color: $gray800;
    border: 2px solid $primary;
    cursor: pointer;
    width: 75px;
    flex-shrink: 1000;
    transform: scale(1);
    transition: transform 250ms, background-color 250ms;

    &:hover {
      transform: scale(1.05);
      transition: transform 250ms, background-color 250ms;
      background-color: $primaryLight;
    }
  }
}

.slide__contact-form {
  border-radius: 15px;
  display: none;
  flex-direction: column;
  padding: 18px;
  box-shadow: 0 4px 10px $black25;
  width: 300px;
  background-color: $secondaryLight;
  margin: 15px;
  z-index: 2;
}

.contact-form {
  &__title {
    text-align: center;
    color: $gray800;
    // font-size: 24px;
    font-size: 1.5rem;
  }

  &__label {
    color: $gray800;

    &--padding {
      padding: 8px 0;
    }
  }

  &__input-wrapper {
    margin: 10px 10px 0;
  }

  &__input {
    outline: 2px solid $gray300;
    border: none;
    border-radius: 2px;
    height: 30px;
    margin-bottom: 15px;
    font-family: nunito;
    // font-size: 15px;
    font-size: 0.9375rem;
    padding: 3px 6px;

    &--text-area {
      height: 120px;
      resize: none;
      padding: 6px;
    }

    &--radio {
      accent-color: $primary;
      transform: scale(1.3);
      cursor: pointer;
    }
  }

  &__cursor {
    cursor: pointer;
  }

  &__button {
    height: 40px;
    color: $white;
    background-color: $gray800;
    border-radius: 3px;
    border: none;
    // font-size: 18px;
    font-size: 1.125rem;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__row-item {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.input-hide {
  display: none;
}

.social-message-image {
  height: 7.5rem;
  margin: 10px 80px;
  opacity: 50%;
}

.social-links-text {
  text-align: center;
  margin: 10px;
  font-size: 1rem;
  color: $gray600;
}

.social-links-wrapper {
  display: flex;
  justify-content: center;
}

.social-image {
  // width: 30px;
  width: 1.875rem;
  // height: 30px;
  height: 1.875rem;
  margin: 0 5px 10px;
  cursor: pointer;
}

.calendar-image {
  display: none;
}

.input-invalid {
  outline: 2px solid $red;
  color: $red;
}

.input-required {
  color: $red;
}

.input-required-text {
  color: $red;
  opacity: 80%;
  font-style: italic;
  font-size: smaller;

  &--hide {
    display: none;
  }
}

.form-submit-title {
  font-size: 30px;
}

.form-submit-icon {
  width: 100px;
  opacity: 50%;
  margin: 20px;
  align-self: center;
}

.slide-wrapper--form-submit {
  display: flex;
  justify-content: center;
  height: 100%;
}

.bio-logo {
  margin-right: 10px;
  object-fit: cover;

  &--85 {
    width: 85px;
  }

  &--150 {
    width: 150px;
  }

  &--gtc {
    width: 180px;
    border-radius: 5px;
    padding: 4px;
    background-color: #8d0302;
  }

  &--round {
    border-radius: 100px;
  }

  &--met {
    width: 85px;
    padding: 4px;
    background-color: $black;
    border-radius: 5px;
  }
}

.bio-logo-img-wrapper {
  height: 50px;
}

.bio-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 30px 30px;
  width: 135px;
}

.bio-experience {
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: smaller;
  align-items: center;
  flex-wrap: wrap;
}

.bio-logo-text {
  margin: 10px;
  color: $black;
}

.bio-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column {
  flex-direction: column;
}

.mobile {
  display: block;
}

.desktop {
  display: none;
}

.align-center {
  align-items: center;
}

.testimonial {
  max-width: 350px;
  text-align: center;
  font-style: italic;
  color: $gray600;
  margin: 20px;
}

.studio-info__list {
  padding-inline-start: 1em;
}
