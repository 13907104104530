@import 'vars.scss';

.esa-margin-top {
  margin-top: 62px;
}

.landing-page-image {
  height: 62.4375rem;
  width: 62.5rem;
  left: -10rem;
}

.data-loading {
  transform: unset;
}

.mobile-calendar {
  display: none !important;
}

.desktop-calendar {
  display: flex !important;
}

.calendar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &__days {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  &__nav {
    display: flex;
    align-items: center;
    height: 75%;
    transition: transform 400ms;

    &:hover {
      transform: scale(1.1);
      transition: transform 400ms;
    }
  }

  &__nav-button {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    background-color: $primaryLight;
    border: 2px solid $primary;
    color: $primary;
    cursor: pointer;

    &:disabled {
      background-color: $gray100;
      border-color: $gray300;
      color: $gray300;
    }
  }
}

.header {
  &__day {
    font-size: x-small;
  }

  &__dayNum {
    font-size: small;
    font-weight: bold;
  }

  &__text-wrapper {
    width: 50px;
    height: 50px;
    padding: 7px;
    border-radius: 200px;
    background-color: $primaryLight;
    box-shadow: 0 1.5px 2px $black25;
    transform: scale(1);
  }
}

.day {
  width: 100%;
  margin: 5px;
  margin-right: 0px;
  padding-right: 5px;
  padding-bottom: unset;
  border-right: 1.5px solid $gray100;
  border-bottom: unset;
  height: 100%;
  display: block;

  &__last {
    border-right: unset;
  }

  &__header {
    padding-bottom: 8px;
    padding-right: unset;
    border-bottom: 1.5px solid $gray100;
    border-right: unset;
    margin-bottom: 8px;
    margin-right: unset;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__body {
    width: unset;
    display: block;
  }

  &__event {
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 5px;
    text-align: center;
    background-color: $primaryLight;
    box-shadow: 0 1.5px 2px $black25;

    &--unavailable {
      border: unset;
      background-color: unset;
      box-shadow: unset;
    }
  }

  &__text {
    font-size: small;
    &--small {
      font-size: smaller;
      line-height: 12px;
    }

    &--unavailable {
      font-weight: bold;
      color: $gray600;
      text-align: center;
    }
  }
}

.nav-wrapper {
  padding-right: unset;
}

.nav {
  margin: 35px 35px 55px 45px;
  justify-content: center;
}

.nav-items {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
}

.nav-title {
  font-size: 3.125rem;
  display: block;

  &--color {
    font-size: 3.125rem;
  }

  &__nav {
    display: none;
  }
}

.slides {
  height: calc(95% - 180px);
  margin: 0 5vw 0 15vw;
  border-radius: 20px;
  top: 180px;
  max-width: 1080px;
  max-height: 720px;
  transition: all 0.6s;

  &--tall {
    height: calc(100% - 180px);
    margin: 0 5vw 0 15vw;
    border-radius: 20px 20px 0 0;
    max-height: 100%;
    transition: all 0.6s;
  }
}

.slide-wrapper {
  overflow-y: scroll;
  height: 100%;
}

.slide {
  &--consult {
    height: 100%;
  }

  &__sections {
    display: flex;
    flex-direction: row;
    width: unset;
    justify-content: space-between;
    padding: 30px;
    overflow-y: unset;
    margin-top: unset;

    &--no-padding {
      padding: 0 30px;
    }

    &--no-padding-bottom {
      padding: 30px 30px 0px;
    }

    &--no-margin {
      margin-top: 0;
    }
  }

  &__header {
    border-bottom: 2px solid $gray100;
    padding: 30px 30px 0 30px;
    position: sticky;
    top: 0;
    margin: 0;
    background-color: $white;
    z-index: 3;
    width: initial;
  }

  &__header-back {
    display: none;
  }

  &__text {
    &--small {
      // font-size: 14px;
      font-size: 0.875rem;
    }

    &--justify {
      text-align: justify;
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    align-items: unset;
  }
}

.pricing-chart {
  margin-bottom: unset;
  width: 500px;
  margin: 40px;
}

.about-slide--no-line {
  border-bottom: none;
}

.about-wrapper {
  display: flex;
  flex-direction: row;

  &__column--margin {
    margin-right: 10px;
    width: 50%;
  }
}

.calendar-image {
  position: absolute;
  z-index: 1;
  width: 175px;
  transform: translate(160px, -35px);
  display: block;
}

.slide__contact-form {
  display: flex;
}

.social-message-image {
  display: none;
}

.social-links-wrapper {
  margin-bottom: unset;
}

.social-links-text {
  margin: 20px;
  // font-size: 12px;
  font-size: 1rem;
}

.social-image {
  width: 2.25rem;
  height: 2.25rem;
  margin: 0 10px 15px;
}

.slide__contact-form {
  transform: scale(0.85);
}

.contact-form {
  &__row {
    flex-direction: row;

    &-item:first-child {
      margin-right: 10px;
    }
  }
}

.form-submit-icon {
  width: 150px;
}

.form-submit-title {
  font-size: 40px;
}

.headshot-wrapper {
  flex-direction: row;
}

.headshot-detail {
  margin-right: 40px;
}

.bio-img-wrap {
  flex-direction: row;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}
