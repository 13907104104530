.day-label {
  width: 100px;
  height: 2rem;
  line-height: 2rem;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 0.875rem;
}

.availability-wrapper--last {
  margin-bottom: 120px;
}

.slide__contact-form {
  transform: scale(1) translate(0);
}
